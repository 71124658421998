import { YapilyHttpClient } from './../../../http_clients/YapilyHttpClient';
import { Router } from "aurelia-router";
import { autoinject, observable } from "aurelia-framework";
import { PLATFORM } from "aurelia-pal";
import { NewsletterHttpClients } from "http_clients/NewsletterHttpClients";
import { json } from "aurelia-fetch-client";
import { checkResponseStatus } from "http_clients/checkResponseStatus";
import { I18N } from "aurelia-i18n";
import { kStringMaxLength } from "buffer";
import { UsersHttpClients } from "http_clients/UsersHttpClients";
import { YapilyBanksDetailsModel } from 'components/models/YapilyModels/YapilyBanksDetailsModel';
import { YapilyBankDetailsModel } from 'components/models/YapilyModels/YapilyBankDetailsModel';
import { default as env } from '../../../../config/environment.json'
import { DialogService } from 'aurelia-dialog';
import { Youtubevideo } from '../../../components/youtubevideo/youtubevideo'

@autoinject
export class Welcome {
  private animated_sections = [];
  private percent_animation_start = 80 / 100;
  private window_height;
  private checkPosition;
  private init;
  private addbtn;
  private defferedPrompt;
  private setDeferredPrompt;
  private isPWAInstalled: boolean = false;
  private showMessage: boolean = false;
  private emailWasSent: string = "Email was sent";
  private isLoading: boolean = false;
  private isActive: number = 2;
  private displayError: boolean = false;
  private shaking: boolean = false;
  private errorMessage: string = "";

  private what_is_banka_content;
  private multi_account_content;
  private charge_content;
  private content1: boolean = true;
  private content2: boolean = false;
  private content3: boolean = false;

  /** For newsletter */
  private email: string = "";
  private email_news: string = "";
  private name: string = "";
  private name_news: string = "";
  private environment: string = "";

  /** For bank search function */
  private allBanksSupportedByYapily: YapilyBanksDetailsModel;
  private searchResultsBanksSupported: Array<YapilyBankDetailsModel> = [];
  @observable private search_term_bank: string = "";
  private errorNoBank: boolean = false;
  

  constructor(
    private newsletterHttpClients: NewsletterHttpClients,
    private i18n: I18N,
    private router: Router,
    private userHttpClients: UsersHttpClients,
    private yapilyHttpClient: YapilyHttpClient,
    private dialogService: DialogService
  ) {
    this.environment = env.welcome_title;
    this.window_height = window.innerHeight;
    this.init = (e) => {
      this.window_height = window.innerHeight;
    };
  }

  async activate() {
    this.setDeferredPrompt = (event) => {
      event.preventDefault();
      this.defferedPrompt = event;
    };
  }

  attached() {
    window.addEventListener("beforeinstallprompt", this.setDeferredPrompt);
  }

  detached() {
    window.removeEventListener("beforeinstallprompt", this.setDeferredPrompt);
  }

  bind() {
    this.loadAllBanksSupported();
  }

  watch() {
    this.dialogService.open({ viewModel: Youtubevideo, model: 'Good or Bad?', lock: false }).whenClosed(response => {
      if (!response.wasCancelled) {
      } else {
      }
    });
  }

  async loadAllBanksSupported() {
    let httpRequest = await this.yapilyHttpClient.fetch('/yapily/banks/available/fr');
    let httpIsOk = await checkResponseStatus(httpRequest);
    this.allBanksSupportedByYapily = await httpIsOk.json();
  }

  /** Search specified bank, among suported Banks by Yapily returns array */
  searchAvailableBanks() {
    this.searchResultsBanksSupported = [];
    
    if(this.search_term_bank === "" || !this.allBanksSupportedByYapily)
      return;
      
    for(let bank of this.allBanksSupportedByYapily.data) {
      let splitBankNames = bank.fullName.toLowerCase().split(" ");
      let splitSearch = this.search_term_bank.toLowerCase().split(" ");
      let foundWords = 0;

      for(let i = 0; i < splitSearch.length; i++) {
        if(splitBankNames.indexOf(splitSearch[i]) > -1) {
          foundWords++;
        }
      }
      
      (foundWords === splitSearch.length) ? this.searchResultsBanksSupported.push(bank) : '';  

      if(this.searchResultsBanksSupported.length > 4) break;
    }
    this.errorNoBank = (this.searchResultsBanksSupported.length < 1);
    return false;
  }

  search_term_bankChanged() {
    this.errorNoBank = false;
  }

  async checkIfOurPWAIsInstalled() {
  }

  async getMobileOperatingSystem() {
    const userAgent = navigator.userAgent || navigator.vendor;

    /* Android browsers*/
    if (/android/i.test(userAgent)) {
      if (this.defferedPrompt != null) this.defferedPrompt.prompt();

      this.defferedPrompt.userChoice.then((choice) => {
        if (choice.outcome === "accepted") {
          this.router.navigateToRoute("install_success");
        }
        this.defferedPrompt = null;
      });
    }

    /* IOS Browser */
    if (/iPad|iPhone|iPod/.test(userAgent)) {
      this.router.navigateToRoute("install_iphone");
      return "iOS";
    }

    /** Desktop browser that support PWA install (BeforeInstallPromptEvent)*/
    if (this.defferedPrompt != null) {
      this.defferedPrompt.prompt();
      this.defferedPrompt.userChoice.then((choice) => {
        if (choice.outcome === "accepted") {
          this.router.navigateToRoute("install_success");
        }
        this.defferedPrompt = null;
      });
    } else {
      /* Browser that *don't support* PWA install OR that have it already installed. */
      this.router.navigateToRoute("login")
    }
  }

  async postNewsLetterEmail() {
    this.isLoading = true;
    this.showMessage = false;
    this.displayError = false;
    let httpRequest = await this.newsletterHttpClients.fetch("/newsletters", {
      method: "POST",
      body: json({
        email: this.email || this.email_news,
        name: this.name || this.name_news,
      }),
    });

    if (httpRequest.ok) {
      this.isLoading = false;
      this.showMessage = true;
      let request = await this.userHttpClients.fetch("/beta/register", {
        method: "POST",
        body: json({
          email: this.email || this.email_news,
          name: this.name || this.name_news,
        }),
      });
    } else {
      let json_response = await httpRequest.json();
      if (httpRequest.status == 409) {
        this.displayError = true;
        this.errorMessage = this.i18n.tr("home.error.welcome_error_already_exist");
        this.shakeError();
      }
      if (json_response.errors.msg[0].msg == "IS_EMPTY") {
        this.displayError = true;
        this.errorMessage = this.i18n.tr("home.error.welcome_error_is_empty");
        this.shakeError();
      }
      if (json_response.errors.msg[0].msg == "EMAIL_ALREADY_EXIST") {
        this.displayError = true;
        this.errorMessage = this.i18n.tr("home.error.welcome_error_already_exist");
        this.shakeError();
      }
      if (json_response.errors.msg[0].msg == "NOT_AN_EMAIL") {
        this.displayError = true;
        this.errorMessage = this.i18n.tr("home.error.welcome_error_not_an_email");
        this.shakeError();
      }
    }
  }

  async shakeError() {
    this.isLoading = false;
    this.shaking = true;
    setTimeout(() => {
      this.shaking = false;
    }, 200);
  }

  showContent(content_to_show: string) {
    switch (content_to_show) {
      case "what_is_banka":
        if (this.what_is_banka_content.style.maxHeight !== "50rem") {

          this.content1 = true; 
          this.what_is_banka_content.style.maxHeight = "50rem";
          this.what_is_banka_content.style.paddingTop = "1.5rem";
        }
        else {
          this.content1 = false
          this.what_is_banka_content.style.maxHeight = "0rem";
          this.what_is_banka_content.style.paddingTop = "0rem"
        }

        this.content2 = false
        this.content3 = false
        this.charge_content.style.maxHeight = "0rem";
        this.charge_content.style.paddingTop = "0rem"
        this.multi_account_content.style.maxHeight = "0rem";
        this.multi_account_content.style.paddingTop = "0rem"
        break;

      case "multi_account":
        if (this.multi_account_content.style.maxHeight !== "50rem"){
          this.content2 = true;
          this.multi_account_content.style.maxHeight = "50rem";
          this.multi_account_content.style.paddingTop = "1.5rem";
        }
        else {
          this.content2 = false;
          this.multi_account_content.style.maxHeight = "0rem";
          this.multi_account_content.style.paddingTop = "0rem";
        }
        
        this.content1 = false
        this.content3 = false
        this.charge_content.style.maxHeight = "0rem";
        this.charge_content.style.paddingTop = "0rem";
        this.what_is_banka_content.style.maxHeight = "0rem";
        this.what_is_banka_content.style.paddingTop = "0rem";
        break;

      case "charge":
        if (this.charge_content.style.maxHeight !== "50rem"){
          this.content3 = true;
          this.charge_content.style.maxHeight = "50rem";
          this.charge_content.style.paddingTop = "1.5rem";
        }
        else {
          this.content3 = false;
          this.charge_content.style.maxHeight = "0rem";
          this.charge_content.style.paddingTop = "0rem";
        }

        this.content1 = false
        this.content2 = false
        this.what_is_banka_content.style.maxHeight = "0rem";
        this.what_is_banka_content.style.paddingTop = "0rem";
        this.multi_account_content.style.maxHeight = "0rem";
        this.multi_account_content.style.paddingTop = "0rem";
        break;
    }
  }
}

// Imports
var ___HTML_LOADER_IMPORT_0___ = new URL("/static/img/arrow_left.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_1___ = new URL("/static/svg/filter-funnel.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_2___ = new URL("/static/svg/down-arrow-download.svg", import.meta.url);
// Module
var code = `<template>
  <require from="./transactions.scss"></require>
  <require from="../../../components/transaction-component/transaction-component"></require>
  <require from="../../../components/transaction-bsv-component/transaction-bsv-component"></require>
  <require from="../../../components/loader-ring/loader-ring"></require>
  
  <section id="transactions" class="flex-column">
    <div id="header" class="flex-row">
      <img id="thumbnail" click.delegate="router.navigateBack()" src="${___HTML_LOADER_IMPORT_0___}">
      <h1 t="home.transactions.transactions1"></h1>
      <div class="flex-grow"></div>
      <div class="dropdown">
        <ul if.bind="isDropDown">
          <li id="drop_filter_7d" click.delegate="filterTransactions(7)">last week</li>
          <li id="drop_filter_30d" click.delegate="filterTransactions(30)">last month</li>
          <li id="drop_filter_120d" click.delegate="filterTransactions(120)">last quarter</li>
          <li id="drop_filter_365d" click.delegate="filterTransactions(365)">last year</li>
          <li id="drop_filter_all" click.delegate="filterTransactions('all')">all time</li>
        </ul>
        <img src="${___HTML_LOADER_IMPORT_1___}" click.delegate="isDropDown = !isDropDown"/>
      </div>
      <img src="${___HTML_LOADER_IMPORT_2___}" style="margin-left: 0.5rem;" click.delegate="download()">
    </div>
    <!-- Loading -->
    <div class="flex-grow" if.bind="loading"></div>
    <loader-ring if.bind="loading"></loader-ring>
    <div class="flex-grow" if.bind="loading"></div>
    <form class="inputs">
      <div>
        <input value.bind="search_term" type="text" i18n="[placeholder]home.recherche" />
        <i class="fas fa-search"></i>
      </div>
    </form>
    <!--Transaction-component-->
    <transaction-component if.bind="!loading" loading.bind="loading"
      transactions.bind="search_results_transactions" limit.bind="false">
    </transaction-component>
  </section>
</template>
`;
// Exports
export default code;
import { SingletonService } from "singleton";
import { autoinject, computedFrom, inject } from "aurelia-framework";
import { AuthService } from "aurelia-auth";
import { Router } from "aurelia-router";
import { YapilyAccountModel } from "components/models/YapilyModels/YapilyAccountModel";
import { YapilyBankModel } from "components/models/YapilyModels/YapilyBankModel";
import { YapilyHttpClient } from "http_clients/YapilyHttpClient";
import { checkResponseStatus } from "http_clients/checkResponseStatus";
import ENDPOINTS from "endpoints";
import { BankaService } from "services/banka.service";
import { WalletsManager, WalletInfo } from "services/WalletManager.service";
import { YapilyTransactionModel } from "components/models/YapilyModels/YapilyTransactionModel";
import { YapilyTransactionsModel } from "components/models/YapilyModels/YapilyTransactionsModel";
import { connectTo } from "aurelia-store";
import { IGenericTransaction } from "components/models/YapilyModels/IGenericTransaction";
import { State } from "state";

@connectTo()
@autoinject
export class Transactions {
  private transactions: Array<IGenericTransaction>;
  private search_term: string = "";
  private dateFilter: string = "all";
  private isDropDown: boolean = false;

  constructor(
    private authService: AuthService,
    private router: Router,
    private singleton: SingletonService,
    private yapilyhttpclient: YapilyHttpClient,
    private bankaService: BankaService,
    private walletManager: WalletsManager,
    private state: State
  ) {}

  attached() {}

  /**
   * @return toutes les banques et les comptes et appelle les txs
   */

  bind() {
    this.transactions = this.state.wallets[this.state.selectedAccountIndex].transactions
  }

  getDateLimit(filter: string): Date | null {
    const now = new Date();
    switch (filter) {
      case "7d":
        return new Date(now.setDate(now.getDate() - 7));
      case "30d":
        return new Date(now.setDate(now.getDate() - 30));
      case "120d":
        return new Date(now.setDate(now.getDate() - 120));
      case "365d":
        return new Date(now.setFullYear(now.getFullYear() - 1));
      case "all":
      default:
        return null;
    }
  }

  @computedFrom("transactions", "search_term", "dateFilter")
  get search_results_transactions(): Array<IGenericTransaction> {
    if (!this.transactions) {
      return [];
    }

    let filteredTransactions = this.transactions;

    // Filter by date
    const dateLimit = this.getDateLimit(this.dateFilter);
    if (dateLimit) {
      filteredTransactions = filteredTransactions.filter(item => item.createdAt >= dateLimit);
    }

    // Filter by search_term
    if (this.search_term != "") {
      let searchTerm = this.search_term.toString().toLowerCase();

      filteredTransactions = filteredTransactions.filter(item => {
        return (
          item.createdAt.toString().toLowerCase().indexOf(searchTerm) > -1 ||
          (item.senderNote == undefined ? "" : item.senderNote).toString().toLowerCase().indexOf(searchTerm) > -1 ||
          item.amount.toString().toLowerCase().indexOf(searchTerm) > -1 ||
          item.senderIdentification.toLowerCase().indexOf(searchTerm) > -1 ||
          item.senderName.toLowerCase().indexOf(searchTerm) > -1 
        );
      });
    }

    return filteredTransactions;
  }

  // update date filter
  filterTransactions(days: number | string) {
    switch (days) {
      case 7:
        this.dateFilter = "7d";
        break;
      case 30:
        this.dateFilter = "30d";
        break;
      case 120:
        this.dateFilter = "120d";
        break;
      case 365:
        this.dateFilter = "365d";
        break;
      case "all":
      default:
        this.dateFilter = "all";
        break;
    }
    this.isDropDown = false;
  }

  download() {
    const transactions = this.search_results_transactions;
    if (transactions.length === 0) {
      alert("No transactions to download.");
      return;
    }

    const accountIdentification = this.state.wallets[this.state.selectedAccountIndex].identification;
    if (this.state.wallets[this.state.selectedAccountIndex].isBlockchain) {
      const headers = `Coming from account: ${accountIdentification}\nCreated At, Currency, Amount, Sender Name, Sender Identification, Sender Note, Transaction ID\n`;
  
      const csvData = transactions.map(transaction => 
        `${transaction.createdAt.toISOString()}, ${transaction.currency}, ${transaction.amount}, ${transaction.senderName}, ${transaction.senderIdentification}, ${transaction.senderNote}, ${transaction._id}`
      ).join("\n");
  
      const csvContent = headers + csvData;
      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
      const link = document.createElement('a');
      const url = URL.createObjectURL(blob);
  
      link.setAttribute('href', url);
      link.setAttribute('download', 'transactions.csv');
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }

    else {

      const headers = `Coming from account: ${accountIdentification}\nCreated At, Currency, Amount, Sender Name, Sender Identification, Sender Note\n`;
  
      const csvData = transactions.map(transaction => 
        `${transaction.createdAt.toISOString()}, ${transaction.currency}, ${transaction.amount}, ${transaction.senderName}, ${transaction.senderIdentification}, ${transaction.senderNote}`
      ).join("\n");
  
      const csvContent = headers + csvData;
      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
      const link = document.createElement('a');
      const url = URL.createObjectURL(blob);
  
      link.setAttribute('href', url);
      link.setAttribute('download', 'transactions.csv');
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
}